import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.login, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }

  logout() {
    localStorage.removeItem('userData')
    localStorage.removeItem('role')
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageEtudSalt)
    localStorage.removeItem(this.jwtConfig.storageRecuSalt)
  }

  setUserData(value) {
    localStorage.setItem(this.jwtConfig.storageUserNameKey, value)
  }

  getUserData() {
    return localStorage.getItem(this.jwtConfig.storageUserNameKey)
  }


  setRole(value) {
    localStorage.setItem(this.jwtConfig.storageRoleKey, value)
  }

  getRole() {
    return localStorage.getItem(this.jwtConfig.storageRoleKey)
  }

  newUser(...args) {
    return axios.post(this.jwtConfig.newUser, ...args, { headers: { "x-access-token": this.getToken() } })
  }

  allUsers() {
    return axios.get(this.jwtConfig.allUsers, { headers: { "x-access-token": this.getToken() } })
  }

  deleteUser(salt) {
    return axios.delete(this.jwtConfig.deleteUser + salt, { headers: { "x-access-token": this.getToken() } })
  }

  editUser(salt, ...args) {
    return axios.put(this.jwtConfig.editUser + salt, ...args, { headers: { "x-access-token": this.getToken() } })
  }


  newCategorie(...args) {
    return axios.post(this.jwtConfig.newCategorie, ...args, { headers: { "x-access-token": this.getToken() } })
  }

  allCategories() {
    return axios.get(this.jwtConfig.allCategories, { headers: { "x-access-token": this.getToken() } })
  }

  deleteCategorie(salt) {
    return axios.delete(this.jwtConfig.deleteCategorie + salt, { headers: { "x-access-token": this.getToken() } })
  }

  editCategorie(salt, ...args) {
    return axios.put(this.jwtConfig.editCategorie + salt, ...args, { headers: { "x-access-token": this.getToken() } })
  }


  newGuide(...args) {
    return axios.post(this.jwtConfig.newGuide, ...args, { headers: { "x-access-token": this.getToken() } })
  }

  allGuides() {
    return axios.get(this.jwtConfig.allGuides, { headers: { "x-access-token": this.getToken() } })
  }

  deleteGuide(salt) {
    return axios.delete(this.jwtConfig.deleteGuide + salt, { headers: { "x-access-token": this.getToken() } })
  }

  editGuide(salt, ...args) {
    return axios.put(this.jwtConfig.editGuide + salt, ...args, { headers: { "x-access-token": this.getToken() } })
  }


  newQuestion(...args) {
    return axios.post(this.jwtConfig.newQuestion, ...args, { headers: { "x-access-token": this.getToken() } })
  }

  allQuestions() {
    return axios.get(this.jwtConfig.allQuestions, { headers: { "x-access-token": this.getToken() } })
  }

  deleteQuestion(salt) {
    return axios.delete(this.jwtConfig.deleteQuestion + salt, { headers: { "x-access-token": this.getToken() } })
  }

  editQuestion(salt, ...args) {
    return axios.put(this.jwtConfig.editQuestion + salt, ...args, { headers: { "x-access-token": this.getToken() } })
  }


  newNews(...args) {
    return axios.post(this.jwtConfig.newNews, ...args, { headers: { "x-access-token": this.getToken() } })
  }

  allNews() {
    return axios.get(this.jwtConfig.allNews, { headers: { "x-access-token": this.getToken() } })
  }

  deleteNews(salt) {
    return axios.delete(this.jwtConfig.deleteNews + salt, { headers: { "x-access-token": this.getToken() } })
  }

  editNews(salt, ...args) {
    return axios.put(this.jwtConfig.editNews + salt, ...args, { headers: { "x-access-token": this.getToken() } })
  }


  newOrganization(...args) {
    return axios.post(this.jwtConfig.newOrganization, ...args, { headers: { "x-access-token": this.getToken() } })
  }

  allOrganizations() {
    return axios.get(this.jwtConfig.allOrganizations, { headers: { "x-access-token": this.getToken() } })
  }

  deleteOrganization(salt) {
    return axios.delete(this.jwtConfig.deleteOrganization + salt, { headers: { "x-access-token": this.getToken() } })
  }

  editOrganization(salt, ...args) {
    return axios.put(this.jwtConfig.editOrganization + salt, ...args, { headers: { "x-access-token": this.getToken() } })
  }


  newMedical(...args) {
    return axios.post(this.jwtConfig.newMedical, ...args, { headers: { "x-access-token": this.getToken() } })
  }

  allMedicals() {
    return axios.get(this.jwtConfig.allMedicals, { headers: { "x-access-token": this.getToken() } })
  }

  deleteMedical(salt) {
    return axios.delete(this.jwtConfig.deleteMedical + salt, { headers: { "x-access-token": this.getToken() } })
  }

  editMedical(salt, ...args) {
    return axios.put(this.jwtConfig.editMedical + salt, ...args, { headers: { "x-access-token": this.getToken() } })
  }


  newTransplant(...args) {
    return axios.post(this.jwtConfig.newTransplant, ...args, { headers: { "x-access-token": this.getToken() } })
  }

  allTransplants() {
    return axios.get(this.jwtConfig.allTransplants, { headers: { "x-access-token": this.getToken() } })
  }

  deleteTransplant(salt) {
    return axios.delete(this.jwtConfig.deleteTransplant + salt, { headers: { "x-access-token": this.getToken() } })
  }

  editTransplant(salt, ...args) {
    return axios.put(this.jwtConfig.editTransplant + salt, ...args, { headers: { "x-access-token": this.getToken() } })
  }


  newMembre(...args) {
    return axios.post(this.jwtConfig.newMembre, ...args, { headers: { "x-access-token": this.getToken() } })
  }

  allMembres() {
    return axios.get(this.jwtConfig.allMembres, { headers: { "x-access-token": this.getToken() } })
  }

  deleteMembre(salt) {
    return axios.delete(this.jwtConfig.deleteMembre + salt, { headers: { "x-access-token": this.getToken() } })
  }

  editMembre(salt, ...args) {
    return axios.put(this.jwtConfig.editMembre + salt, ...args, { headers: { "x-access-token": this.getToken() } })
  }
}
