import url from "../../../views/Config"
// ** Auth Endpoints
export default {
  login: `${url}api/login`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',


  newUser: `${url}api/user`,
  allUsers: `${url}api/users`,
  deleteUser: `${url}api/user/`,
  editUser: `${url}api/user/`,

  newCategorie: `${url}api/categorie`,
  allCategories: `${url}api/categories`,
  deleteCategorie: `${url}api/categorie/`,
  editCategorie: `${url}api/categorie/`,


  newGuide: `${url}api/guideMedical`,
  allGuides: `${url}api/guideMedicals`,
  deleteGuide: `${url}api/guideMedical/`,
  editGuide: `${url}api/guideMedical/`,


  newQuestion: `${url}api/maladieRein`,
  allQuestions: `${url}api/maladieReins`,
  deleteQuestion: `${url}api/maladieRein/`,
  editQuestion: `${url}api/maladieRein/`,


  newNews: `${url}api/newsMedical`,
  allNews: `${url}api/newsMedicals`,
  deleteNews: `${url}api/newsMedical/`,
  editNews: `${url}api/newsMedical/`,


  newOrganization: `${url}api/organisation`,
  allOrganizations: `${url}api/organisations`,
  deleteOrganization: `${url}api/organisation/`,
  editOrganization: `${url}api/organisation/`,


  newMedical: `${url}api/centreMedical`,
  allMedicals: `${url}api/centreMedicals`,
  deleteMedical: `${url}api/centreMedical/`,
  editMedical: `${url}api/centreMedical/`,

  newTransplant: `${url}api/kidneyTransplant`,
  allTransplants: `${url}api/kidneyTransplants`,
  deleteTransplant: `${url}api/kidneyTransplant/`,
  editTransplant: `${url}api/kidneyTransplant/`,


  newMembre: `${url}api/dialyseFamile`,
  allMembres: `${url}api/dialyseFamiles`,
  deleteMembre: `${url}api/dialyseFamile/`,
  editMembre: `${url}api/dialyseFamile/`,
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageUserNameKey: 'userData',
  storageEtudSalt: 'etdData',
  storageRecuSalt: 'recuData',
  StorageRcSlt: 'rcSlt',
  storageRoleKey: 'role',
  storageRefreshTokenKeyName: 'refreshToken'
}
